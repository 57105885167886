import React from 'react';
import Plyr from 'react-plyr';

import Details from './Details';

class VideoPlayer extends React.PureComponent {
  play() {
    if (this.plyr) this.plyr.play();
  }

  render() {
    if (!this.props.item) return null;
    const item = this.props.item;
    return (
      <div className="p-video-player">
        <Plyr
          ref={c => (this.plyr = c)}
          type="video"
          poster={item.image}
          url={item.media_url}
          preload={false}
        />
        <Details body item={item} />
      </div>
    );
  }
}

export default VideoPlayer;
